import { Box, Button, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosConfig'
import CTAButton from '../../components/CustomButton/CTAButton'
import CustomTextfield from '../../components/CutomTextfield/CustomTextfield'
import { initializeUser } from '../../redux/reducers/UserReducer'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { useSnackbar } from '../../context/SnackBarContext'
import { User } from '../../redux/types'
import { initializeIsAuthenticated } from '../../redux/reducers/IsAuthenticatedReducer'

interface SignInPageProps {}

type Errors = {
  email_address: string
  password: string
}

type UserCredentials = {
  email_address: string
  password: string
}

const SignInPage: React.FC<SignInPageProps> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { showMessage } = useSnackbar()
  const isAuthenticated = useAppSelector(
    (state) => state.persistedReducer.isAuthenticated
  )
  const [userCredentials, setUserCredentials] = useState<UserCredentials>({
    email_address: '',
    password: '',
  })
  const userState: User = useAppSelector((state) => state.persistedReducer.user)
  const [errors, setErrors] = useState<Errors>({
    email_address: '',
    password: '',
  })
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)

  useEffect(() => {
    if (isAuthenticated) {
      switch (userState?.role?.id) {
        case 3:
          navigate('/student/account')
          break

        case 4:
          if (userState.scholarship_provider.provider_name) {
            navigate('/provider/dashboard')
          } else {
            navigate(`/provider/account/${userState.id}/view-profile`)
          }
          break
        default:
          navigate('/')
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  function handleEmail(inputValue: string) {
    setUserCredentials((prevUserCredentials) => ({
      ...prevUserCredentials,
      email_address: inputValue.toLowerCase(),
    }))
  }

  function handlePassword(inputValue: string) {
    setUserCredentials((prevUserCredentials) => ({
      ...prevUserCredentials,
      password: inputValue,
    }))
  }

  const handleSignIn = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isValidEmail = emailRegex.test(userCredentials.email_address)

    const validationConditions = [
      {
        condition: !isValidEmail || !userCredentials.email_address,
        field: 'email_address',
        message: 'Please provide a valid registered email address.',
      },
      {
        condition: !userCredentials.password,
        field: 'password',
        message: 'Please provide a valid password.',
      },
    ]

    const errorMessages = validationConditions
      .filter(({ condition }) => condition)
      .map(({ message }) => message)
    const hasErrors = errorMessages.length > 0

    if (hasErrors) {
      showMessage('Please fill in the required details.', 'error')
      const newErrors = validationConditions.reduce<{ [key: string]: string }>(
        (acc, { condition, field, message }) => {
          if (condition) {
            acc[field] = message
          }
          return acc
        },
        {}
      )

      setErrors({ ...errors, ...newErrors })
    } else {
      setIsButtonLoading(true)
      try {
        const response = await axiosInstance.post(
          `/api/v1/login`,
          userCredentials,
          {
            withCredentials: true,
          }
        )

        setErrors({
          email_address: '',
          password: '',
        })
        setIsButtonLoading(false)
        dispatch(initializeUser(response.data))
        dispatch(initializeIsAuthenticated(true))
      } catch (error: any) {
        setIsButtonLoading(false)
        if (error) {
          showMessage(error.response.data.message ?? 'Login failed.', 'error')
          setErrors({
            email_address: '',
            password: '',
          })
          dispatch(initializeIsAuthenticated(false))
        }
      }
    }
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '50px',
        marginBlock: '40px',
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: '64px',
          fontWeight: '700',
          textAlign: 'center',
          color: 'var(--secondary-color)',
        }}
      >
        Sign-in
      </Typography>
      <CustomTextfield
        label="Email address"
        value={userCredentials.email_address.toLowerCase()}
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleEmail(e.target.value)
        }
        placeholder="Input your email"
        error={errors.email_address ?? ''}
      />
      <CustomTextfield
        type="password"
        label="Password"
        value={userCredentials.password}
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handlePassword(e.target.value)
        }
        placeholder="Input your password"
        error={errors.password ?? ''}
      />
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 !important',
        }}
      >
        <Button
          id="forgot-password"
          disableRipple
          variant="text"
          sx={{
            cursor: 'pointer',
            fontSize: '16px',
            color: '#767676',
            fontWeight: '300',
            fontStyle: 'italic',
            textAlign: 'start',
            textTransform: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={() => {
            showMessage(
              'Contact scholaris@sence1.com to change password.',
              'info'
            )
          }}
        >
          Forgot password?
        </Button>
        <Button
          id="to-sign-up"
          disableRipple
          component={RouterLink}
          to="/sign-up"
          variant="text"
          sx={{
            cursor: 'pointer',
            fontSize: '16px',
            color: '#767676',
            fontWeight: '300',
            fontStyle: 'italic',
            textAlign: 'center',
            textTransform: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
        >
          No account yet? Sign-up here
        </Button>
      </Container>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '30px',
        }}
      >
        <CTAButton
          id="sign-in-from-sigin-page"
          handleClick={handleSignIn}
          label="Sign in"
          loading={isButtonLoading}
          styles={{ fontSize: '24px' }}
        />
      </Box>
    </Container>
  )
}

export default SignInPage
